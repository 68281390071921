import $ from 'jquery'

export default class AnchorLink {
  constructor(props) {
    this.anchors = document.querySelectorAll(props.target)
  }
  init() {
    const urlHash = location.hash

    if (this.anchors) {
      this.anchors.forEach(val => {
        val.addEventListener('click', e => {
          e.preventDefault()
          const self = e.currentTarget
          const href = self.getAttribute('href')
          const posTarget = $(href).offset().top
          $('body, html').stop().animate({
            scrollTop: posTarget
          }, 400, 'swing')
        })
      })
    }
    
    window.addEventListener('DOMContentLoaded', (event) => {
      if (urlHash) {
        const urlTarget = document.getElementById(urlHash.replace('#', ''));
        const urlPosition = urlTarget.offsetTop;
        setTimeout(function () {
          window.scroll({
            top: 0
          });
        }, 0);
        setTimeout(function () {
          $("html,body").animate({
            scrollTop: urlPosition
          }, "slow");
        }, 1000);
      }
    });
  }
}
