import $ from 'jquery'
import Screen from "./screen";

export default class WysiwygImg {
  init() {
    $(function () {
      const screen = new Screen()
      let $wysiwygElement = $('.c-box-wysiwyg')

      if ($wysiwygElement) {
        let imgWidth = 0
        let imgHeight = 0
        
        $wysiwygElement.find('img').each(function() {
          imgWidth = $(this).width();
          imgHeight =  $(this).height();

          if(imgHeight > imgWidth) {
            $(this).addClass('portrait')
          }
        })
      }
    })
  }
}
