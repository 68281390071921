import $ from 'jquery'
import { isActive, breakpointMD } from '../_variables'
import Screen from "./screen";

export default class PageTopScroll {
  init() {
    $(function () {
      const screen = new Screen()
      let $pagetopElement = $('.js-pagetop-scroll')

      if ($pagetopElement) {
        let newTop = 0
        let height = 0
        let doc = 0
        let pos = 0
        let posStop = 0
        
        $(window).on('scroll', function () {
          const ftOffset = $('.c-footer').offset().top
          newTop = $(window).scrollTop()
          doc = $(document).height()
          height = $(window).height()

          if (!screen.isPC) {
            pos = 1
            posStop = newTop+height+15
          } else {
            pos = 1
            posStop = newTop+height-70
          }

          if (newTop < pos) {
            $pagetopElement.removeClass(isActive)
          } else {
            $pagetopElement.addClass(isActive)
          }
  
          if(posStop>ftOffset){
            $pagetopElement.addClass("stop");
          } else {
            $pagetopElement.removeClass("stop");
          }
        })
      }
    })
  }
}
