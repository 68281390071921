import $ from "jquery";
import Pagetop from "./modules/pagetop";
import NavGlobal from "./modules/navGlobal";
import headerFixed from "./modules/headerFixed";
import PageTopScroll from "./modules/pagetopScroll";
import gnaviCurrent from "./modules/gnaviCurrent";
import DeviceCheck from "./modules/DeviceCheck";
import AnchorLink from "./modules/anchorLink";
import MatchHeight from "./modules/match_height";
import Screen from "./modules/screen";
import FadeScroll from './modules/fadeScroll';
import CommonAccordian from './modules/accordian'
import WysiwygImg from './modules/wysiwygImg'
import imageCheck from "./modules/imageCheck";
import ObjectFit from "./modules/objectFit";
import CheckForm from "./modules/checkForm";
import FadeIn from "./modules/fadeIn";
import Top from './modules/top'
import Uncheck from './modules/uncheck'

/* ----------------------------------------------------------
  init
---------------------------------------------------------- */
const top = new Top()

const pagetop = new Pagetop({
  target: ".js-pagetop",
});

const anchorLink = new AnchorLink({
  target: ".js-anchor",
});

const matchHeight = new MatchHeight({
  target: ".js-matchHeight-group",
});

const pagetopscroll = new PageTopScroll();

const deviceCheck = new DeviceCheck({
  target: "html",
});

const fadeScroll = new FadeScroll({
  target: '.js-fade-scroll'
});

const navGlobal = new NavGlobal();

const wysiwygImg = new WysiwygImg();

const objectFit = new ObjectFit();

const fadeIn = new FadeIn();

const commonAccordion = new CommonAccordian({
  target: '.js-cmn-accordian'
});

const checkForm = new CheckForm();

const uncheck = new Uncheck()

top.init()
pagetop.init();
anchorLink.init();
navGlobal.init();
pagetopscroll.init();
wysiwygImg.init();
deviceCheck.init();
fadeScroll.init();
gnaviCurrent();
commonAccordion.init();
matchHeight.init();
headerFixed();
imageCheck();
checkForm.init();
objectFit.init();
fadeIn.init();
uncheck.init();

/* ----------------------------------------------------------
  Screen
---------------------------------------------------------- */
const screen = new Screen();
$(window).on("resize", screen.size());
$(window).on("breakpointChange");
