
import { animateFade } from '../_variables'

export default class fadeScroll {
  constructor(props) {
    this.elm = document.querySelector(props.target);
  }

  init() {
    if (this.elm) {
      window.addEventListener("scroll", (event) => {
        event.preventDefault();
        this.fadeBox();
      });
    }
  }

  fadeBox() {
    const itemElement = document.querySelectorAll(
      ".js-fade-scroll .js-fade-scroll-item"
    );
    const winTop = window.pageYOffset + (window.innerHeight * 85) / 100;
    itemElement.forEach((val)=>{
      const boxTop = val.getBoundingClientRect().top + window.pageYOffset;
      if (winTop >= boxTop) {
        if (!val.classList.contains(animateFade.fadeInDown)) {
          val.classList.add(animateFade.fadeInDown)
        }
      }
    })
  }

}
