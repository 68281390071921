import $ from 'jquery'

export default class Uncheck {
  init() {
    $(function () {
      if ($('.js-uncheck').length) {
        $('.js-uncheck').on('click', function () {
          $('.c-box-filters').find('input:checkbox').prop('checked',false).removeAttr('checked');
        });
      }
    })
  }
}
